import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FormikTextField from '@/components/CustomTextField';
import { Formik } from 'formik';
import firebase from "gatsby-plugin-firebase"
import '@/styles/login.scss';
import { useDispatch, useSelector } from 'react-redux';
import { loggedIn } from '@/state/app';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { getLocalizedPath } from '@/libraries/hooks/helper/path';

export default () => {
  const [error, setError] = useState(null);
  const { i18n } = useTranslation();

  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector(state => state.app);
  if (isLoggedIn) {
    navigate(getLocalizedPath(i18n.language, '/app'));
    return <></>
  }

  return (
    <Layout>
      <SEO title="Home" />
      <section className="about-banner background-overlay">
      </section>
      <section className="log-in">
        {!isLoggedIn &&
          <Formik
            initialValues={{ email: '', password: '' }}
            validate={values => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, }) => {
              setSubmitting(true);
              setError(null);
              firebase
                .auth()
                .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                .then(() => {
                  return firebase
                    .auth()
                    .signInWithEmailAndPassword(values.email, values.password)
                })
                .then(({ user }) => {
                  return firebase
                    .firestore()
                    .collection('env')
                    .doc(`/dev/users/${user.uid}/`)
                    .get()
                })
                .then(doc => {
                  if (doc.exists) {
                    setSubmitting(false);
                    dispatch(loggedIn(doc.data()));
                    navigate(getLocalizedPath(i18n.language, '/app'));
                  } else {
                    throw new Error("Cannot login. Please try again later");
                  }
                })
                .catch(error => {
                  setError(error.message);
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
                <form onSubmit={handleSubmit}>
                  <h3>Welcome Back to YitNow</h3>
                  <FormikTextField name="email" label="Username" variant="outlined" />
                  <FormikTextField name="password" label="Password" variant="outlined" type="password" />
                  {error &&
                    <span className="error-message">{error}</span>}
                  <button type="submit" disabled={isSubmitting}>
                    Login
                  </button>
                </form>
              )}
          </Formik>
        }
      </section>
    </Layout >
  )
};

